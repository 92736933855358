<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Mantenimiento *" />
      <BorderInput
        v-model="maintenance.description"
        label="Mantenimiento *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="maintenanceError.description"
        message="Ingrese una descripción"
      />
      <BasicLabel label="Ubicación" />
      <BorderInput
        v-model="maintenance.location"
        label="Ubicación"
        @keyup.enter="onSave"
      />
      <FormError :show="maintenanceError.location" />
      <BasicLabel label="Fecha Inicio *" />
      <BorderDate v-model="maintenance.fromDate" label="Fecha Inicio *" />
      <FormError
        :show="maintenanceError.fromDate"
        message="Seleccione una fecha"
      />
      <BasicLabel label="Hora Inicio" />
      <BorderTime v-model="maintenance.fromTime" label="Hora Inicio" />
      <FormError
        :show="maintenanceError.fromTime"
        message="Seleccione una hora"
      />
      <BasicLabel label="Fecha Fin *" />
      <BorderDate v-model="maintenance.toDate" label="Fecha Fin *" />
      <FormError
        :show="maintenanceError.toDate"
        message="Seleccione una fecha válida"
      />
      <BasicLabel label="Hora Fin" />
      <BorderTime v-model="maintenance.toTime" label="Hora Fin" />
      <FormError
        :show="maintenanceError.toTime"
        message="Seleccione una hora"
      />
      <BasicLabel label="Proveedor *" />
      <BorderInput
        v-model="maintenance.provider"
        label="Proveedor *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="maintenanceError.provider"
        message="Ingrese un proveedor"
      />
      <BasicLabel label="Frecuencia" />
      <BorderSelect
        v-model="maintenance.frequency"
        label="Frecuencia"
        :options="store.state.general.frequency"
      />
      <FormError
        :show="maintenanceError.frequency"
        message="Seleccione una frecuencia"
      />
      <BasicLabel label="Comentarios" />
      <OutlinedTextArea v-model="maintenance.comment" />
      <FormError :show="maintenanceError.comment" />
      <PrimaryButton label="Guardar" :click="onSave" />
    </div>
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import BorderTitleSelect from "../../widgets/select/BorderTitleSelect.vue";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";

export default {
  components: {
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
    BorderTitleSelect,
    BorderDate,
    BorderTime,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const maintenance = reactive({
      id: "",
      description: "",
      location: "",
      fromDate: "",
      fromTime: "",
      toDate: "",
      toTime: "",
      provider: "",
      frequency: "",
      comment: "",
    });
    const maintenanceError = reactive({
      description: false,
      location: false,
      fromDate: false,
      fromTime: false,
      toDate: false,
      toTime: false,
      provider: false,
      frequency: false,
      comment: false,
    });

    watchEffect(() => {
      if (maintenance.description) maintenanceError.description = false;
      if (maintenance.location) maintenanceError.location = false;
      if (maintenance.fromDate) maintenanceError.fromDate = false;
      if (maintenance.fromTime) maintenanceError.fromTime = false;
      if (maintenance.toDate) maintenanceError.toDate = false;
      if (maintenance.toTime) maintenanceError.toTime = false;
      if (maintenance.provider) maintenanceError.provider = false;
      if (maintenance.frequency) maintenanceError.frequency = false;
      if (maintenance.comment) maintenanceError.comment = false;
      if (new Date(maintenance.toDate) - new Date(maintenance.fromDate) > 0) {
        maintenanceError.toDate = false;
      }
    });

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/mantenimientos/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch("GENERAL_FREQUENCY");
      store.commit("setLoading", false);
      // if(store.state.openMode === openMode.MODIFY) {
      //   const selected = store.state.maintenance.selectedMaintenances[0]
      //   maintenance.id = selected.id
      //   maintenance.date = selected.date
      //   maintenance.turn = selected.turn
      //   maintenance.apartment = selected.apartment
      //   maintenance.cost = selected.cost
      //   maintenance.comment = selected.comment
      // }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    const validate = () => {
      let valid = true;

      if (!maintenance.description) {
        maintenanceError.description = true;
        valid = false;
      }
      if (!maintenance.fromDate) {
        maintenanceError.fromDate = true;
        valid = false;
      }
      // if (!maintenance.fromTime) {
      //   maintenanceError.fromTime = true;
      //   valid = false;
      // }
      if (!maintenance.toDate) {
        maintenanceError.toDate = true;
        valid = false;
      }
      // if (!maintenance.toTime) {
      //   maintenanceError.toTime = true;
      //   valid = false;
      // }
      if (new Date(maintenance.toDate) - new Date(maintenance.fromDate) < 0) {
        maintenanceError.toDate = true;
        valid = false;
      }
      if (!maintenance.provider) {
        maintenanceError.provider = true;
        valid = false;
      }
      // if (!maintenance.frequency) {
      //   maintenanceError.frequency = true;
      //   valid = false;
      // }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);
      let response = null;
      const request = {
        buildingId: store.state.general.selectedBuilding,
        id: maintenance.id,
        description: maintenance.description,
        location: maintenance.location,
        fromDate: maintenance.fromDate,
        fromTime: maintenance.fromTime,
        toDate: maintenance.toDate,
        toTime: maintenance.toTime,
        provider: maintenance.provider,
        maintenanceStatusId: 1,
        frequencyId: maintenance.frequency,
        comment: maintenance.comment,
      };
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch("MAINTENANCE_UPDATE", request);
      } else {
        response = await store.dispatch("MAINTENANCE_CREATE", request);
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedMaintenances", []);
        router.push("/mantenimientos/listado");
      } else {
        alert(response.message);
      }
    };

    return {
      store,
      maintenance,
      maintenanceError,
      onSave,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
